<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-button
          class="button"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
          size="small"
          >添加优惠券</el-button
        >
        <el-button
          class="button"
          type="primary"
          @click="reviewCoupons"
          size="small"
          >审核优惠券</el-button
        >
      </div>
      <div class="pageFucView_right">
        <el-radio-group
          style="margin-top: 10px"
          v-model="queryForm.type"
          @change="buttonTab"
        >
          <el-radio-button
            type="button"
            v-for="item in button_state"
            :key="item.id"
            :label="item.id"
          >
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <el-table :data="list" @selection-change="setSelectRows">
      <el-table-column align="center" prop="id" label="ID" width="80">
      </el-table-column>
      <el-table-column align="center" prop="name" label="优惠券名">
      </el-table-column>
      <el-table-column
        align="center"
        :formatter="formRange"
        prop="range_of_application"
        label="适用范围"
      >
      </el-table-column>
      <el-table-column
        align="center"
        :formatter="formType"
        prop="preferential_type"
        label="优惠券类型"
      >
      </el-table-column>
      <el-table-column align="center" label="发放时间" show-overflow-tooltip>
        <template #default="{ row }">
          <div>开始:{{ row.grant_begin_time | formatDate }}</div>
          <div>结束:{{ row.grant_end_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :formatter="formatterIsCheck"
        prop="state"
        label="审核状态"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
<!--          <el-button type="text" @click="issueCoupons(row)"-->
<!--            >发放优惠券</el-button-->
<!--          >-->
          <el-button type="text" @click="receiptOfCoupons(row)"
            >领取情况</el-button
          >
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination
        layout="prev, pager, next"
        :page-size="queryForm.pageSize"
        :current-page.sync="queryForm.page"
        :total="count"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/theTimeStamp";
import Edit from "./components/Edit";
import { apiGetCouponList, apiDeleteCoupon } from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  components: { Edit },
  props: [],
  data() {
    return {
      query_time: [],
      imgShow: true,
      selectRows: "",
      // listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        type: 0,
        operation: 0,
      },
      current: 0,
      button_state: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "满减",
        },
        {
          id: 2,
          name: "折扣",
        },
        {
          id: 3,
          name: "运费",
        },
        {
          id: 4,
          name: "赠品",
        },
        {
          id: 5,
          name: "审核拒绝",
        },
      ],
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData();
  },
  mounted() {},
  methods: {
    formRange(row) {
      if (row.range_of_application == 1) {
        return "限定分类";
      } else if (row.range_of_application == 2) {
        return "限定商品";
      } else if (row.range_of_application == 3) {
        return "限定店铺";
      } else {
        return "通用";
      }
    },
    formType(row) {
      if (row.preferential_type == 1) {
        return "折扣";
      } else if (row.preferential_type == 2) {
        return "运费";
      } else if (row.preferential_type == 3) {
        return "赠品";
      } else {
        return "满减";
      }
    },
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1;
      } else if (num == 2) {
        this.queryForm.type = 2;
      } else if (num == 3) {
        this.queryForm.type = 3;
      } else if (num == 4) {
        this.queryForm.type = 4;
      } else if (num == 5) {
        this.queryForm.type = 5;
      } else {
        this.queryForm.type = 0;
      }
      this.fetchData();
    },
    //
    handleEdit(row) {
      this.$router.push({
        name: "editorcoupons",
        query: {
          id: row.id,
        },
      });
    },
    reviewCoupons() {
      this.$router.push({
        path: "reviewCoupons",
      });
    },
    receiptOfCoupons(row) {
      if (row.id) {
        this.$router.push({
          path: "receiptOfCoupons",
          query: {
            id: row.id,
          },
        });
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm(`你确定要删除${row.name}吗`, null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          apiDeleteCoupon({ id: row.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.fetchData();
            } else {
              this.$message.error(res.message);
            }
          });
        });
      } else {
        this.$message.error("未选中任何行");
        return false;
      }
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.page = val;
      this.fetchData();
    },
    queryData() {
      this.queryForm.page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      apiGetCouponList(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
          this.count = res.data.count;
        }
      });
      this.listLoading = false;
    },

    // 状态
    formatterIsCheck: function (row) {
      return row.state === 0
        ? "未审核"
        : row.state == 1
        ? "已审核"
        : row.state == 2
        ? "已驳回"
        : "暂无";
    },
  },
};
</script>
<style>
.page {
  background-color: #ffffff;
  padding: 20px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
</style>
